import { getUserRole } from "helpers/fakebackend_helper";
import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  const {allowedRoles}=props;
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  // if(getUserRole()!==allowedRoles){
  //   return <Navigate to="/unauthorized" />;
  // }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
