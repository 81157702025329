import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";

import { Badge, Button, Card, CardBody } from "reactstrap";



import TableContainer from "../../../components/Common/TableContainer";
import { Link } from "react-router-dom";
import moment from "moment";

const LatestBookings = (data) => {


  const [modal1, setModal1] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

  const columns = useMemo(
    () => [
      {
        header: 'No',
       
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const no = cellProps.row.index + 1;
          return <span>{no}</span>
        }
      },
      {
        header: "Booking No",
        accessorKey: "booking_no",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'First Name',
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Last Name',
        accessorKey: "lastName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Email',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "emailId"
      },
      {
        header: 'Phone',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "phoneNo"
      },
      {
        header: 'Ticket Type',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketType"
      },
      {
        header: 'Unit Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "unitAmount"
      },
      {
        header: 'Quantity',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketCount"
      },
      {
        header: 'Ticket Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketAmount"
      },
      
      {
        header: 'Processing Fee',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "processingFee"
      },
      {
        header: 'Ticket Fee',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketFee"
      },
      {
        header: 'Total Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "grandTotal"
      },
      {
        header: 'Discount Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "discountAmount"
      },
      {
        header: "Status",
        accessorKey: "bookingStatus",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.bookingStatus) {
            case "confirmed":
              return <span className="badge badge-soft-success">Confirmed</span>;
            case "failed":
              return <span className="badge badge-soft-danger">Failed</span>;
            
            default:
              return <></>;
          }
        },
      },
      {
        header: 'Booked Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return new moment(cellProps.row.original.createdAt).format('DD-MMMM-yyyy hh:mm:ss')
        
        },
        accessorKey: "createdAt"
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Latest Bookings Details</div>

          <TableContainer
            columns={columns}
            data={data.data}
            isGlobalFilter={false}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestBookings.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestBookings);
