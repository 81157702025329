import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import LatestBookings from "./LatestBookings";
import { getAllBookingByOrganizer, getAllEventByOrganizer } from "api";

const SponsorDashboard = props => {
  //meta title
  document.title = "Dashboard ";
  const [bookingData, setBookingData] = useState([])
  const [eventList, setEventList] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [widgetReport, setWidgetReport] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getAllEventByOrganizer();
        const allBooking = await getAllBookingByOrganizer()
        let totalTicket = allBooking.reduce((acc, event) => acc + event.ticketAmount, 0)
        let totalTicketCount = allBooking.reduce((acc, event) => acc + parseInt(event.ticketCount), 0)
        const reports = [
          { title: "Total Events", iconClass: "bx-copy-alt", description: events.length },
          { title: "Total Bookings", iconClass: "bx-archive-in", description: totalTicketCount },
          {
            title: "Average Revenue",
            iconClass: "bx-purchase-tag-alt",
            description: `$${totalTicket.toFixed(2)}`,
          },
        ];
        setWidgetReport(reports)
        setBookingData(allBooking)


       
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
    return () => {
      // Cleanup code here
    };
  }, []);

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {(widgetReport || [])?.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx " + report.iconClass + " font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <LatestBookings data={bookingData} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SponsorDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(SponsorDashboard);
