import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import TableContainer from "components/Common/TableContainer";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getAllBookingByEvent, getAllEventByOrganizer } from "api";
import moment from "moment";
import Spinners from "components/Common/Spinner";

const OrganizersBookings = props => {
  //meta title
  document.title = "";
  const [bookingData, setBookingData] = useState([])
  const [selectedEvent, setSelectedEvent] = useState([])
  const [eventList, setEventList] = useState([])
  const [isLoading, setLoading] = useState(false)

 
  const columns = useMemo(
    () => [
      {
        header: 'No',
       
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const no = cellProps.row.index + 1;
          return <span>{no}</span>
        }
      },
      {
        header: "Booking No",
        accessorKey: "booking_no",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'First Name',
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Last Name',
        accessorKey: "lastName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Email',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "emailId"
      },
      {
        header: 'Phone',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "phoneNo"
      },
      {
        header: 'Ticket Type',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketType"
      },
      {
        header: 'Ticket Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "unitAmount"
      },
      {
        header: 'Quantity',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketCount"
      },
      {
        header: 'Processing Fee',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "processingFee"
      },
      {
        header: 'Ticket Fee',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "ticketFee"
      },
      {
        header: 'Total Amount',
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "grandTotal"
      },
      {
        header: "Status",
        accessorKey: "bookingStatus",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.bookingStatus) {
            case "confirmed":
              return <span className="badge badge-soft-success">Confirmed</span>;
            case "failed":
              return <span className="badge badge-soft-danger">Failed</span>;
            
            default:
              return <></>;
          }
        },
      },
      {
        header: 'Booked Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return new moment(cellProps.row.original.createdAt).format('DD-MMMM-yyyy hh:mm:ss')
        
        },
        accessorKey: "createdAt"
      },
      
      
    ],
    []
  );
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const events = await getAllEventByOrganizer();
        const transformedEvents = events.map(event => ({
          label: event.eventName, 
          value: event.slugId 
        }));
       
        setEventList(transformedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
    return () => {
      // Cleanup code here
    };
  }, []);
  
  function handleSelectEvent(event) {
    setLoading(true);
    getBookingDetails(event.value);

    setSelectedEvent(event);

  }
  const getBookingDetails = async (slug) => {
    try {
      const response = await getAllBookingByEvent(slug);
      setBookingData(response);
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }finally{
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Bookings")}
          />

          <Row>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col xl="3">
                    <div className="col-md-12">
                      <Select
                        value={selectedEvent}
                        onChange={(data) => {
                          handleSelectEvent(data)
                        }}
                        options={eventList}
                        className="select2-selection"
                      />
                      
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                  {isLoading ? <Spinners setLoading={setLoading} /> :
                    <TableContainer
                      columns={columns}
                      data={bookingData||[]}
                      isCustomPageSize={false}
                      isPagination={true}
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    />
                  }
                  </Col>
                </Row>

              </CardBody>
            </Card>

          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

OrganizersBookings.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(OrganizersBookings);
