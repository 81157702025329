import { AxiosError } from 'axios';
import toast, { Toaster } from 'react-hot-toast';

export const handleError = (error) => {
  if (error?.response?.data?.errors&&error?.response?.data?.errors.length>0) {
    const errorList=error?.response?.data?.errors;
    errorList.map((err) => {
       toast.error(err)
    })
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Server responded with a non-2xx status:', error.response.status, error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('No response received:', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error setting up request:', error.message);
  }
};
