import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { getAllEvents, getAllOrganizer, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";


const ForumList = props => {
    //meta title
    document.title = "Dashboard | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [forumList, setForumList] = useState([])
    const [reports, setReports] = useState([
        { title: "Events", iconClass: "bx-copy-alt", description: 0 },
        { title: "Organizers", iconClass: "bx-archive-in", description: 0 },
        {
            title: "Revenue",
            iconClass: "bx-purchase-tag-alt",
            description: 0,
        },
    ])

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEvents();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.slugId,
                    label: item.eventName
                }
            })
            setEventList(eventOptions);




            setReports([
                { title: "Total Registration", iconClass: "bx-copy-alt", description: respAllEvent.length },
                { title: "Total Interest WINTECH ONAM  ", iconClass: "bx-archive-in", description: orgRes.length },
                {
                    title: "Total Interest WINTECH ONAM After Party",
                    iconClass: "bx-purchase-tag-alt",
                    description: 0,
                },
            ])
        } catch (error) {
            console.log(error);
        }
    }
    const fetchForumByEvent = async (eventId) => {
        try {
            const resp = await getForumBySlug(eventId);
            setForumList(resp)
            const beforeParty = resp.filter((item) => item.beforeParty === "Yes")
            const afterParty = resp.filter((item) => item.afterParty === "Yes")
            setReports([
                { title: "Total Registration", iconClass: "bx-copy-alt", description: resp.length },
                { title: "Total Interest WINTECH ONAM  ", iconClass: "bx-archive-in", description: beforeParty.length },
                {
                    title: "Total Interest WINTECH ONAM After Party",
                    iconClass: "bx-purchase-tag-alt",
                    description: afterParty.length,
                },
            ])
        } catch (error) {
            console.log(error);
        }
    }
    const columns = useMemo(
        () => [
          
          {
            header: 'Name',
            accessorKey: 'name',
            enableColumnFilter: false,
            enableSorting: true,
            
          },
          {
            header: 'Email',
            accessorKey: 'email',
            enableColumnFilter: false,
            enableSorting: true,
            
          },
          {
            header: 'Phone No',
            accessorKey: 'phone',
            enableColumnFilter: false,
            enableSorting: true,
            
          },
          {
            header: 'Are you interest WINTECH ONAM 2024?',
            accessorKey: 'beforeParty',
            enableColumnFilter: false,
            enableSorting: true,
            
          },
          {
            header: 'Are you interest WINTECH ONAM AFTER PARTY ?',
            accessorKey: 'afterParty',
            enableColumnFilter: false,
            enableSorting: true,
            
          },
          {
            header: 'Register Date',
            accessorKey: 'createdAt',
            enableColumnFilter: false,
            enableSorting: true,
            cell: (row) => {
                return (
                    <span>{moment(row.getValue()).format("MMMM Do YYYY, h:mm:ss a")}</span>
                )
            }
            
          },
        ])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        <Col xl="12">
                            <Row>
                                {(reports || [])?.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 className="mb-0">{report.description}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="5">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchForumByEvent(data.value)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <TableContainer
                                            columns={columns}
                                            data={forumList || []}
                                            isGlobalFilter={true}
                                            isPagination={true}
                                            SearchPlaceholder="Search..."
                                            isCustomPageSize={true}
                                            isAddButton={false}
                                           
                                            buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                           
                                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                            theadClass="table-light"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            pagination="pagination"
                                        />
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ForumList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ForumList);
