import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";


import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { createOrganizer, deleteUser, getAllCountries, getAllEventByOrganizer, getAllOrganizer, updateUser } from "api";
import { handleError } from "utils/errorHandler";

const OrganizerList = () => {

  //meta title
  document.title = "Organizer List | EventoX";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [country, setCountry] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(0)
  const [selectedImage, setSelectedImage] = useState("")
  const [status, setStatus] = useState("active")

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      email: (contact && contact.email) || "",
      phoneNo: (contact && contact.phoneNo) || "",
      location: (contact && contact.location) || "",
      profileImage: (contact && contact.profileImage) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      phoneNo: Yup.string().required("Please Enter  phone"),
    //   country: Yup.array().required("Please select country"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      setLoading(true)
      if (isEdit) {
        let data = new FormData();
        data.append("name", values["name"]);
        data.append("phoneNo", values["phoneNo"]);
        data.append("email", values["email"]);
        data.append("location", selectedCountry);
        data.append("profileImage", values["profileImage"]);
        data.append("status", status);
        data.append("url", values["url"]);
        try{
        const resp=await updateUser(contact.organizerId, data);
        getOrganizers()
        toast.success("Organizer updated Successfully")
        }
        catch(error){
          toastr.error("Error Creating Organizer")
          handleError(error)
        }finally{
          setLoading(false)
        }
       
       
        setIsEdit(false);
        validation.resetForm();
      } else {
       
        let data = new FormData();
        data.append("name", values["name"]);
        data.append("phoneNo", values["phoneNo"]);
        data.append("email", values["email"]);
        data.append("location", selectedCountry);
        data.append("username", values["email"]);
        data.append("password", "password123");
        data.append("userRole", 2);
        data.append("profileImage", values["profileImage"]);
        data.append("url", values["url"]);
        try{
        // save new user
        const createResp=await createOrganizer(data);
        if(createResp){
          toast.success("Organizer Created Successfully")
        }else{
          toastr.error("Error Creating Organizer")
        }
        }catch(error){
          console.log(error)
        }finally{
        getOrganizers()
        validation.resetForm();
        setLoading(false)
        setSelectedImage("")
        }
      }
      toggle();
    },
  });



  useEffect(() => {
    // toastr.error("jbsjb", ":jbsjbsd")
    
    const fetchData = async () => {
      try {
        getOrganizers();
        let countryResp=await getAllCountries()
        setCountry(countryResp)
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);

  const getOrganizers=async()=>{
    try{
    let orgRes = await getAllOrganizer();
    setUsers(orgRes)
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact({
      organizerId: user.organizerId,
      name: user.name,
      phoneNo: user.phone,
      email: user.email,
      location:user.country,
      profileImage:user.profilePic,
    });
    setSelectedImage(user.profilePic)
    setSelectedCountry(user?.Country?.countryId)
    setIsEdit(true);

    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.organizerId) {
      try{
        setLoading(true)
      deleteUser(contact.organizerId).then((res)=>{
        setLoading(false)
          toast.success("Organizer Deleted Successfully")
          getOrganizers()
       
      });
    }catch(error){
      toast.error("Error Deleting Organizer "+error?.message);
      handleError(error)
    }

    }
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const handleImageChange = (event) => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      validation.setFieldValue('profileImage', file)
      // validation.setFieldValue('profileImage', reader.result)
    };
    reader.readAsDataURL(file);
  };
  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "profilePic",
        cell: (cell) => (
          <>
            {!cell.getValue() ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{cell.row.original.name.charAt(0)} </span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cell.getValue()} alt="" />
              </div>
            )}
          </>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <>
              <h5 className='font-size-14 mb-1'>
                <Link to='#' className='text-dark'>{cell.getValue()}</Link>
              </h5>
              <p className="text-muted mb-0">{cell.row.original.designation}</p>
            </>
          )
        }
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        enableColumnFilter: false,
        enableSorting: true,
      },
      
      {
        header: 'Country',
        accessorKey: 'Country.countryName',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <div>
              {
                
                  <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>
            
              }
            </div>
          );
        },
      },
      {
        header: 'Action',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <div

              
                className="text-success btn-hover"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </div>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original; onClickDelete(userData);
                }}>
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

 
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Organizer List" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={users || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        handleUserClick={handleUserClicks}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                        buttonName="New Organizer"
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Organizer" : "Add Organizer"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name &&
                              validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name &&
                          validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Phone No</Label>
                        <Input
                          name="phoneNo"
                          label="Phone"
                          type="text"
                          placeholder="Phone no"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNo || ""}
                          invalid={
                            validation.touched.phoneNo &&
                              validation.errors.phoneNo
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phoneNo &&
                          validation.errors.phoneNo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phoneNo}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Country</Label>
                        
                        <select className="form-select pageSize" name="country" onChange={(e)=>setSelectedCountry(e.target.value)}>
                        <option value="" >Select Country</option>
                        {country&&country.map((cou,index)=>
                            <option value={cou.countryId} key={index} selected={cou.countryId===selectedCountry}>{cou.countryName}</option>
                        )}
                     
                       
                      </select>
                        {validation.touched.country &&
                          validation.errors.country ? (
                          <FormFeedback type="invalid">
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {isEdit&&
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        
                        <select className="form-select pageSize" name="status" onChange={(e)=>setStatus(e.target.value)}>
                        <option value="" >Select Status</option>
                        <option value="active" selected>Active</option>
                        <option value="inactive" >Inactive</option>

                       
                       
                      </select>
                       
                      </div>
                      }
                      <div className="mb-3">
                        <Label className="form-label">Web/Social Media reference URL</Label>
                        <Input
                          name="url"
                          label="URL"
                          type="text"
                          placeholder="https://www.example.com"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.url || ""}
                          
                        />
                        
                      </div>
                      <div className="mb-3">
                      <Label className="form-label">Project Image</Label>

                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label htmlFor="project-image-input" className="mb-0" id="projectImageInput">
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip placement="right" target="projectImageInput">
                              Select Image
                            </UncontrolledTooltip>
                            <input className="form-control d-none" id="project-image-input" type="file" accept="image/png, image/gif, image/jpeg" onChange={handleImageChange} />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded-circle">
                              <img src={selectedImage || ''} id="projectlogo-img" alt="" className="avatar-md h-auto rounded-circle" />
                            </div>
                          </div>
                        </div>
                        {validation.touched.projectImage && validation.errors.projectImage ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.projectImage}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          {isLoading?"Loading....":"Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(OrganizerList);
