import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { getAllEvents, getAllOrganizer, getBookingByEventId, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const pagination = true;
const paginationPageSize = 500;
const paginationPageSizeSelector = [200, 500, 1000];
const AttendeeReport = props => {
    //meta title
    document.title = "Booking List | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [bookingList, setBookingList] = useState([])
    const [reports, setReports] = useState([
        { title: "Total Booking", iconClass: "bx-copy-alt", description: 0 },
        { title: "Total Ticket Purchased", iconClass: "bx-archive-in", description: 0 },
        {
            title: "Total Revenue",
            iconClass: "bx-purchase-tag-alt",
            description: 0,
        },
    ])

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEvents();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }

    const fetchBookingByEvent = async (eventId) => {
        const getBookingResp = await getBookingByEventId(eventId)

        const activeBooking = getBookingResp.filter((item) => item.bookingStatus === 'confirmed');
        const currency=activeBooking[0]?.currency
        setBookingList(activeBooking)
        const ticketPurchased = activeBooking.filter((item) => item.bookedBy === "0")
        const totalRevenue = ticketPurchased.reduce((acc, item) => acc + item.totalTicketAmount, 0)
        setReports([
            { title: "No. of Attendees", iconClass: "bx-copy-alt", description: `${activeBooking.length}` },
            { title: "No. of tickets", iconClass: "bx-archive-in", description: ticketPurchased.length },
            {
                title: "Total Revenue",
                iconClass: "bx-purchase-tag-alt",
                description: `${currency} ${totalRevenue}`,
            },
        ])

        console.log("🚀 ~ fetchBookingByEvent ~ getBookingResp:", getBookingResp)

    }
    const columns = useMemo(
        () => [

            {
                headerName: 'Booked By',
                cellRenderer: params => {
                    return params.data.bookingObject.primaryContactDetails.firstName + ' ' + params.data.bookingObject.primaryContactDetails.lastName
                },
                filter: true

            },
            {
                headerName: 'Ticket',
                cellRenderer: params => {
                    return "1"
                }

            },
            {
                headerName: 'Ticket Type',
                cellRenderer: params => {
                    return params.data.bookingObject.selectedTicket[0].ticketType
                }

            },

            {
                headerName: 'Name',
                field: 'Contact.firstName',
            },
            {
                headerName: 'Email',
                field: 'Contact.email',
                filter: true

            },
            {
                headerName: 'Phone',
                field: 'Contact.phoneNo',
                filter: true

            },
            {
                headerName: 'Booking Date',
                cellRenderer: params => {
                    return moment(params.data.createdAt).format('DD-MMM-YYYY HH:mm:ss')
                },

            },



        ])
    const sideBar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
        defaultToolPanel: 'filters',
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        <Col xl="12">
                            <Row>
                                {(reports || [])?.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 className="mb-0">{report.description}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="5">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchBookingByEvent(data.value)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{
                                            height: '500px',
                                            width: '100%'
                                        }} className="ag-theme-quartz">
                                            <AgGridReact
                                                columnDefs={columns}
                                                rowData={bookingList}
                                                sideBar={sideBar}
                                                rowGroupPanelShow="always"
                                                pagination={pagination}
                                                paginationPageSize={paginationPageSize}
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                            />
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(AttendeeReport);

