import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import LatestTranaction from "./LatestTranaction";
import { getAllEvents, getAllOrganizer } from "api";

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | EventoX";
  const [eventList, setEventList] = useState([])
  const [organizerList, setOrganizerList] = useState([])
  const [reports, setReports] = useState([
    { title: "Events", iconClass: "bx-copy-alt", description: 0 },
    { title: "Organizers", iconClass: "bx-archive-in", description: 0 },
    {
      title: "Revenue",
      iconClass: "bx-purchase-tag-alt",
      description: 0,
    },
  ])

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll=async()=>{
    try {
      
      const respAllEvent = await getAllEvents();
      let orgRes = await getAllOrganizer();
      setOrganizerList(orgRes)
      setEventList(respAllEvent)
      setReports([
        { title: "Events", iconClass: "bx-copy-alt", description: respAllEvent.length },
        { title: "Organizers", iconClass: "bx-archive-in", description: orgRes.length },
        {
          title: "Revenue",
          iconClass: "bx-purchase-tag-alt",
          description: 0,
        },
      ])
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
           
           <Row>
           <Col xl="12">
           <Row>
                {(reports || [])?.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i className={"bx " + report.iconClass + " font-size-24"}></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
           </Col>
           </Row>
           <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
