import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const OverviewChart = ({ bookingList,dataColors }) => {
  console.log("🚀 ~ OverviewChart ~ bookingList:", bookingList)
  const overViewChartColors = getChartColorsArray(dataColors);
  const [chartData, setChartData] = useState([])
  
  useEffect(() => {
    const sampleData = bookingList.map(booking => [booking.createdAt, booking.totalTicketCount]);
    setChartData(sampleData)
  }, [bookingList])
  // Sample data: [timestamp, number of bookings]
  // const sampleData = [
  //   [1625097600000, 10], // July 1, 2021
  //   [1625184000000, 15], // July 2, 2021
  //   [1625270400000, 8],  // July 3, 2021
  //   // ... add more data points
  // ];

  const [series, setSeries] = useState([
    {
      name: "Number of Bookings",
      data: chartData,
    },
  ]);

  const [options, setOptions] = useState({
    chart: { toolbar: "false" },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    markers: { size: 0, style: "hollow" },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
    },
    tooltip: { x: { format: "dd MMM yyyy" } },
    colors: overViewChartColors,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.6,
        opacityTo: 0.05,
        stops: [42, 100, 100, 100],
      },
    },
  });

  const [activeFilter, setActiveFilter] = useState("all");

  const filterData = (filter) => {
    const currentDate = new Date();
    let filteredData = [];
    console.log("🚀 ~ filterData ~ filter:", filter)

    switch (filter) {
      case "day":
        filteredData = chartData.filter(
          ([timestamp]) =>
            new Date(timestamp).toDateString() === currentDate.toDateString()
        );
        break;
      case "week":
        const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        filteredData = chartData.filter(
          ([timestamp]) => new Date(timestamp) >= oneWeekAgo
        );
        break;
      case "month":
        filteredData = chartData.filter(
          ([timestamp]) =>
            new Date(timestamp).getMonth() === currentDate.getMonth() &&
            new Date(timestamp).getFullYear() === currentDate.getFullYear()
        );
        break;
      case "year":
        filteredData = chartData.filter(
          ([timestamp]) =>
            new Date(timestamp).getFullYear() === currentDate.getFullYear()
        );
        break;
      default:
        filteredData = chartData;
    }

    setSeries([{ ...series[0], data: filteredData }]);
    setActiveFilter(filter);
  };

  useEffect(() => {
    filterData("all");
  }, [chartData]);

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-3">Booking Overview</h4>
            <div>
              <div id="overview-chart" className="apex-charts" dir="ltr">
                <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                  <Button
                    color="light"
                    size="sm"
                    type="button"
                    className={activeFilter === "day" ? "active" : ""}
                    onClick={() => filterData("day")}
                  >
                    Day
                  </Button>
                  <Button
                    color="light"
                    size="sm"
                    type="button"
                    className={activeFilter === "week" ? "active" : ""}
                    onClick={() => filterData("week")}
                  >
                    Week
                  </Button>
                  <Button
                    color="light"
                    size="sm"
                    type="button"
                    className={activeFilter === "month" ? "active" : ""}
                    onClick={() => filterData("month")}
                  >
                    Month
                  </Button>
                  <Button
                    color="light"
                    size="sm"
                    type="button"
                    className={activeFilter === "year" ? "active" : ""}
                    onClick={() => filterData("year")}
                  >
                    Year
                  </Button>
                  <Button
                    color="light"
                    size="sm"
                    type="button"
                    className={activeFilter === "all" ? "active" : ""}
                    onClick={() => filterData("all")}
                  >
                    All
                  </Button>
                </div>
                <div id="overview-chart-timeline">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    height={240}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OverviewChart;