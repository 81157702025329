import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { updateEvent, getEventById, getAllCountries, getAllEventCategory, getAllOrganizer } from "api";
import Select from "react-select";
import { eventDuration, eventTime } from "utils/constants";
import toast, { Toaster } from 'react-hot-toast';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  //meta title
  document.title = "Edit Event | Eventox";

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedThumbImg, setSelectedThumbImg] = useState(null);
  const [selectedBannerImgDesktop, setSelectedBannerImgDesktop] = useState(null);
  const [selectedBannerImgMobile, setSelectedBannerImgMobile] = useState(null);
  const [organizerList, setOrganizerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [selectedOrganizer, setSelectedOrganizer] = useState(null);
  const [selectedEventTime, setSelectedEventTime] = useState(null);
  const [selectedEventDuration, setSelectedEventDuration] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [otherFeesSelected, setOtherFeesSelected] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [onlineEventCategory, setOnlineEventCategory] = useState(null);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [ticketGrouping, setTicketGrouping] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [event, organizers, categories, countries] = await Promise.all([
          getEventById(id),
          getAllOrganizer(),
          getAllEventCategory(),
          getAllCountries()
        ]);

        // Set initial form values
        Object.keys(event).forEach(key => {
          validation.setFieldValue(key, event[key]);
        });

        // Set editor state
        const contentBlock = htmlToDraft(event.eventDescription);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }

        // Set select options
        setOrganizerList(organizers.map(org => ({ value: org.organizerId, label: org.name })));
        setCategoryList(categories.map(cat => ({ value: cat.categoryId, label: cat.categoryName })));
        setCountryList(countries.map(country => ({ value: country.countryId, label: country.countryName })));

        // Set selected values for dropdowns
        setSelectedOrganizer({ value: event.organizerId, label: event.organizerName });
        setSelectedEventTime({ value: event.eventTime, label: event.eventTime });
        setSelectedEventDuration({ value: event.eventDuration, label: event.eventDuration });
        setSelectedCategory({ value: event.categoryId, label: event.categoryName });
        setSelectedEventType({ value: event.eventType, label: event.eventType });
        setSelectedTicketType({ value: event.ticketType, label: event.ticketType });
        setSelectedStatus({ value: event.eventStatus, label: event.eventStatus });
        setOtherFeesSelected({ value: event.showOtherFees ? "yes" : "no", label: event.showOtherFees ? "Yes" : "No" });
        setSelectedTemplate({ value: event.templateName, label: event.templateName });
        setSelectedCountry({ value: event.eventCountry, label: event.countryName });
        setOnlineEventCategory({ value: event.onlineEventType, label: event.onlineEventType });
        setPaymentChannel({ value: event.paymentChannel, label: event.paymentChannel });
        setTicketGrouping({ value: event.ticketGrouping, label: event.ticketGrouping });
        setTicketStatus({ value: event.ticketStatus, label: event.ticketStatus });

        // Set image previews
        setSelectedThumbImg(event.thumbImg);
        setSelectedBannerImgDesktop(event.bannerImgDesktop);
        setSelectedBannerImgMobile(event.bannerImgMobile);

      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load event data");
      }
    };

    fetchData();
  }, [id]);

  const handleAcceptedFiles = (files) => {
    const newImages = files?.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));
    setSelectedFiles([...selectedFiles, ...newImages]);
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const htmlContent = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    validation.setFieldValue('eventDescription', htmlContent);
  };

  const handleImageChange = (event, field) => {
    if (event.target.files[0].size > 2097152) {
      toast.error("File size should be less than 2MB");
    } else {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        if (field === 'thumbImg') setSelectedThumbImg(reader.result);
        if (field === 'bannerImgDesktop') setSelectedBannerImgDesktop(reader.result);
        if (field === 'bannerImgMobile') setSelectedBannerImgMobile(reader.result);
        validation.setFieldValue(field, file);
      };
      reader.readAsDataURL(file);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: '',
      eventDescription: '',
      shortDescription: '',
      thumbImg: '',
      bannerImgDesktop: '',
      bannerImgMobile: '',
      eventLocation: '',
      categoryId: '',
      organizerId: '',
      ticketType: '',
      paymentChannel: '',
      paymentUrl: '',
      eventType: '',
      onlineEventType: '',
      eventStatus: '',
      eventDate: '',
      eventTime: '',
      eventDuration: '',
      eventVenue: '',
      eventAddress1: '',
      eventCountry: 0,
      templateName: '',
      processingFee: 0,
      ticketFee: 0,
      showOtherFees: true,
      ticketGrouping: 'single',
      ticketStatus: ''
    },
    validationSchema: Yup.object({
      // Add validation schema here (same as in CreateEvent)
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
          if (values[key] !== null && values[key] !== undefined) {
            formData.append(key, values[key]);
          }
        });

        selectedFiles.forEach((file) => {
          formData.append("otherImages", file);
        });

        await updateEvent(id, formData);
        toast.success("Event updated successfully");
        navigate("/admin/events"); // Adjust this path as needed
      } catch (error) {
        console.error("Error updating event:", error);
        toast.error(`Failed to update event: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Events" breadcrumbItem="Edit Event" />
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label htmlFor="eventName">Event Name</Label>
                      <Input
                        id="eventName"
                        name="eventName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventName || ""}
                      />
                      {validation.touched.eventName && validation.errors.eventName ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventName}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="eventDescription">Event Description</Label>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                      />
                      {validation.touched.eventDescription && validation.errors.eventDescription ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventDescription}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="shortDescription">Short Description</Label>
                      <Input
                        id="shortDescription"
                        name="shortDescription"
                        type="textarea"
                        rows="3"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.shortDescription || ""}
                      />
                      {validation.touched.shortDescription && validation.errors.shortDescription ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.shortDescription}</FormFeedback>
                      ) : null}
                    </div>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Thumbnail Image</Label>
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute bottom-0 end-0">
                              <Label htmlFor="project-image-input1" className="mb-0">
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="bx bxs-image-alt"></i>
                                  </div>
                                </div>
                              </Label>
                              <input className="form-control d-none" id="project-image-input1" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'thumbImg')} />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                <img src={selectedThumbImg || ''} alt="" className="avatar-md h-auto" />
                              </div>
                            </div>
                          </div>
                          {validation.touched.thumbImg && validation.errors.thumbImg ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.thumbImg}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Banner Image Desktop</Label>
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute bottom-0 end-0">
                              <Label htmlFor="project-image-input2" className="mb-0">
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="bx bxs-image-alt"></i>
                                  </div>
                                </div>
                              </Label>
                              <input className="form-control d-none" id="project-image-input2" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'bannerImgDesktop')} />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                <img src={selectedBannerImgDesktop || ''} alt="" className="avatar-md h-auto" />
                              </div>
                            </div>
                          </div>
                          {validation.touched.bannerImgDesktop && validation.errors.bannerImgDesktop ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.bannerImgDesktop}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">Banner Image Mobile</Label>
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute bottom-0 end-0">
                              <Label htmlFor="project-image-input3" className="mb-0">
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="bx bxs-image-alt"></i>
                                  </div>
                                </div>
                              </Label>
                              <input className="form-control d-none" id="project-image-input3" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'bannerImgMobile')} />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                <img src={selectedBannerImgMobile || ''} alt="" className="avatar-md h-auto" />
                              </div>
                              </div>
                      </div>
                      {validation.touched.bannerImgMobile && validation.errors.bannerImgMobile ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.bannerImgMobile}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <div className="mb-3">
                  <Label htmlFor="projectname-input">Event Category</Label>
                  <Select
                    value={selectedCategory}
                    onChange={(data) => {
                      setSelectedCategory(data);
                      validation.setFieldValue("categoryId", data.value);
                    }}
                    options={categoryList}
                    className="select2-selection"
                  />
                  {validation.touched.categoryId && validation.errors.categoryId ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.categoryId}</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="projectname-input">Organizer</Label>
                  <Select
                    value={selectedOrganizer}
                    onChange={(data) => {
                      setSelectedOrganizer(data);
                      validation.setFieldValue("organizerId", data.value);
                    }}
                    options={organizerList}
                    className="select2-selection"
                  />
                  {validation.touched.organizerId && validation.errors.organizerId ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.organizerId}</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label htmlFor="projectname-input">Ticket Type</Label>
                  <Select
                    value={selectedTicketType}
                    onChange={(data) => {
                      setSelectedTicketType(data);
                      validation.setFieldValue("ticketType", data.value);
                    }}
                    options={[
                      { value: "free", label: "Free" },
                      { value: "paid", label: "Paid" },
                    ]}
                    className="select2-selection"
                  />
                  {validation.touched.ticketType && validation.errors.ticketType ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.ticketType}</FormFeedback>
                  ) : null}
                </div>

                {selectedTicketType?.value === "paid" && (
                  <div className="mb-3">
                    <Label htmlFor="projectname-input">Payment Channel</Label>
                    <Select
                      value={paymentChannel}
                      onChange={(data) => {
                        setPaymentChannel(data);
                        validation.setFieldValue("paymentChannel", data.value);
                      }}
                      options={[
                        { value: "our", label: "Our Channel" },
                        { value: "external", label: "External Channel" },
                      ]}
                      className="select2-selection"
                    />
                    {validation.touched.paymentChannel && validation.errors.paymentChannel ? (
                      <FormFeedback type="invalid" className="d-block">{validation.errors.paymentChannel}</FormFeedback>
                    ) : null}
                  </div>
                )}

                {paymentChannel?.value === "external" && selectedTicketType?.value === 'paid' && (
                  <div className="mb-3">
                    <Label htmlFor="paymentUrl">Payment URL</Label>
                    <Input
                      id="paymentUrl"
                      name="paymentUrl"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.paymentUrl || ""}
                    />
                    {validation.touched.paymentUrl && validation.errors.paymentUrl ? (
                      <FormFeedback type="invalid" className="d-block">{validation.errors.paymentUrl}</FormFeedback>
                    ) : null}
                  </div>
                )}

                <div className="mb-3">
                  <Label htmlFor="projectname-input">Event Type</Label>
                  <Select
                    value={selectedEventType}
                    onChange={(data) => {
                      setSelectedEventType(data);
                      validation.setFieldValue("eventType", data.value);
                    }}
                    options={[
                      { value: "online", label: "Online Event" },
                      { value: "offline", label: "Venue Event" },
                    ]}
                    className="select2-selection"
                  />
                  {validation.touched.eventType && validation.errors.eventType ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventType}</FormFeedback>
                  ) : null}
                </div>

                {selectedEventType?.value === "online" && (
                  <div className="mb-3">
                    <Label htmlFor="projectname-input">What type of online event are you hosting?</Label>
                    <Select
                      value={onlineEventCategory}
                      onChange={(data) => {
                        setOnlineEventCategory(data);
                        validation.setFieldValue("onlineEventType", data.value);
                      }}
                      options={[
                        { value: 'standard_webinar', label: 'Standard Webinar' },
                        { value: 'training_workshop', label: 'Training and Workshop' },
                        { value: 'online_classes', label: 'Online Classes' },
                        { value: 'talk_show', label: 'Talk Show' },
                        { value: 'other', label: 'Other' },
                      ]}
                      className="select2-selection"
                    />
                    {validation.touched.onlineEventType && validation.errors.onlineEventType ? (
                      <FormFeedback type="invalid" className="d-block">{validation.errors.onlineEventType}</FormFeedback>
                    ) : null}
                  </div>
                )}

                <div>
                  <Label>Other Images Files</Label>
                  <Dropzone
                    onDrop={handleAcceptedFiles}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className="dropzone-previews mt-3"
                    id="file-previews"
                  >
                    {selectedFiles.map((file, index) => (
                      <div className="border rounded" key={index}>
                        <div className="d-flex flex-wrap gap-2 p-2">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm bg-light rounded p-2">
                              <img data-dz-thumbnail="" className="img-fluid rounded d-block" src={file.preview} alt={file.name} />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div className="pt-1">
                              <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                              <strong className="error text-danger" data-dz-errormessage></strong>
                            </div>
                          </div>
                          <div className="flex-shrink-0 ms-3">
                            <Button variant="danger" size="sm" onClick={() => {
                              const newImages = [...selectedFiles];
                              newImages.splice(index, 1);
                              setSelectedFiles(newImages);
                            }}>Delete</Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <h5 className="card-title mb-3">Publish</h5>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Event Status</Label>
                  <Select
                    value={selectedStatus}
                    onChange={(data) => {
                      setSelectedStatus(data);
                      validation.setFieldValue("eventStatus", data.value);
                    }}
                    options={[
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "InActive" },
                    ]}
                    className="select2-selection"
                  />
                  {validation.touched.eventStatus && validation.errors.eventStatus ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventStatus}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Ticket Status</Label>
                  <Select
                    value={ticketStatus}
                    onChange={(data) => {
                      setTicketStatus(data);
                      validation.setFieldValue("ticketStatus", data.value);
                    }}
                    options={[
                      { value: "open", label: "Open" },
                      { value: "closed", label: "Closed" },
                    ]}
                    className="select2-selection"
                  />
                  {validation.touched.ticketStatus && validation.errors.ticketStatus ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.ticketStatus}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Template</Label>
                  <Select
                    value={selectedTemplate}
                    onChange={(data) => {
                      setSelectedTemplate(data);
                      validation.setFieldValue("templateName", data.value);
                    }}
                    options={[
                      { value: "entertainment", label: "Entertainment" },
                      { value: "corporate", label: "Corporate" },
                    ]}
                    className="select2-selection"
                  />
                  {validation.touched.templateName && validation.errors.templateName ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.templateName}</FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="card-title mb-3">Event Date Details</h5>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Event Date</Label>
                  <FlatPickr
                    className="form-control d-block"
                    id="eventDate"
                    name="eventDate"
                    placeholder="Select date"
                    options={{
                      mode: "single",
                      dateFormat: 'Y-m-d',
                    }}
                    onChange={(date) => validation.setFieldValue("eventDate", moment(date[0]).format("YYYY-MM-DD"))}
                    value={validation.values.eventDate || ''}
                  />
                  {validation.errors.eventDate && validation.touched.eventDate ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventDate}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Event Time</Label>
                  <Select
                    value={selectedEventTime}
                    onChange={(data) => {
                      setSelectedEventTime(data);
                      validation.setFieldValue("eventTime", data.value);
                    }}
                    options={eventTime}
                    className="select2-selection"
                  />
                  {validation.touched.eventTime && validation.errors.eventTime ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventTime}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label htmlFor="project-status-input">Event Duration</Label>
                  <Select
                    value={selectedEventDuration}
                    onChange={(data) => {
                      setSelectedEventDuration(data);
                      validation.setFieldValue("eventDuration", data.value);
                    }}
                    options={eventDuration}
                    className="select2-selection"
                  />
                  {validation.touched.eventDuration && validation.errors.eventDuration ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventDuration}</FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="card-title mb-3">Event Location Details</h5>
                {selectedEventType?.value === "offline" && (
                  <>
                    <div className="mb-3">
                      <Label htmlFor="eventLocation">Event Google Location</Label>
                      <Input
                        id="eventLocation"
                        name="eventLocation"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventLocation || ""}
                      />
                      {validation.touched.eventLocation && validation.errors.eventLocation ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventLocation}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="eventVenue">Event Venue</Label>
                      <Input
                        id="eventVenue"
                        name="eventVenue"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventVenue || ""}
                      />
                      {validation.touched.eventVenue && validation.errors.eventVenue ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventVenue}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="eventAddress1">Address</Label>
                      <Input
                        id="eventAddress1"
                        name="eventAddress1"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventAddress1 || ""}
                      />
                      {validation.touched.eventAddress1 && validation.errors.eventAddress1 ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventAddress1}</FormFeedback>
                      ) : null}
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <Label htmlFor="projectname-input">Country</Label>
                  <Select
                    value={selectedCountry}
                    onChange={(data) => {
                      setSelectedCountry(data);
                      validation.setFieldValue("eventCountry", data.value);
                    }}
                    options={countryList}
                    className="select2-selection"
                  />
                  {validation.touched.eventCountry && validation.errors.eventCountry ? (
                    <FormFeedback type="invalid" className="d-block">{validation.errors.eventCountry}</FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>

            {selectedTicketType?.value === "paid" && paymentChannel?.value === "our" && (
              <Card>
                <CardBody>
                  <h5 className="card-title mb-3">Pricing Details</h5>
                  <div className="mb-3">
                    <Label htmlFor="processingFee">Processing Fee</Label>
                    <Input
                      id="processingFee"
                      name="processingFee"
                      type="number"
                      placeholder="Enter zero if no processing fee"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.processingFee || ""}
                    />
                    {validation.touched.processingFee && validation.errors.processingFee ? (
                      <FormFeedback type="invalid" className="d-block">{validation.errors.processingFee}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="ticketFee">Ticket Fee</Label>
                    <Input
                      id="ticketFee"
                      name="ticketFee"
                      type="number"
                      placeholder="Enter zero if no ticket fee"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ticketFee || ""}
                    />
                    {validation.touched.ticketFee && validation.errors.ticketFee ? (
                      <FormFeedback type="invalid" className="d-block">{validation.errors.ticketFee}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="project-status-input">Show Processing & ticket fees in the checkout form and ticket</Label>
                    <Select
                      value={otherFeesSelected}
                      onChange={(data) => {
                        setOtherFeesSelected(data);
                        validation.setFieldValue("showOtherFees", data.value === 'yes');
                      }}
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      className="select2-selection"
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="projectname-input">Ticket Grouping</Label>
                    <Select
                      value={ticketGrouping}
                      onChange={(data) => {
                        setTicketGrouping(data);
                        validation.setFieldValue("ticketGrouping", data.value);
                      }}
                      options={[
                        { value: "single", label: "Single" },
                        { value: "group", label: "Group" },
                      ]}
                      className="select2-selection"
                    />
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <div className="text-end mb-4">
              <Button type="submit" disabled={isLoading} color="primary">
                {isLoading ? 'Updating event...' : 'Update Event'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  </div>
</React.Fragment>
  )
}

export default EditEvent;