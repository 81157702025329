import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
    UncontrolledTooltip,
} from "reactstrap";
import { createEventVenue, getAllEvents, getEventVenuesBySlug, updateEventVenue } from "api";
import Select from "react-select";
import Breadcrumbs from "components/Common/Breadcrumb";
import Spinners from "components/Common/Spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';


function EventVenue() {
    document.title = "Event Venue List | EventoX";

    const navigate = useNavigate();
    const [eventVenues, setEventVenues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [eventOptions, setEventOptions] = useState([])
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [statusOptions, setStatusOptions] = useState([
        {value:'active',label:'Active'},
        {value:'inactive',label:'Inactive'}
    ])
    const [selectedStatus, setSelectedStatus] = useState(null)

    const validation = useFormik({
        initialValues:{
            venueName:'',
            venueAddress:'',
            eventDate:'',
            status:'active'
        },
        validationSchema:Yup.object({
            venueName:Yup.string().required("Venue Name Required"),
            eventDate:Yup.string().required("Event Date Required"),
        }),
        onSubmit:async(values) => {
            console.log("🚀 ~ onSubmit:async ~ values:", values)
            values.eventSlug = selectedEvent.value
            try{
            if(isEdit){
                const updateResp = await updateEventVenue(values.venueId,values);
                toast.success("Venue updated successfully");
                setSelectedStatus(null)
                setIsEdit(false)
                toggle();
            }else{
                const createResp = await createEventVenue(values);
                setSelectedStatus(null)
                setIsEdit(false)
                toggle();
                toast.success("Venue created successfully");
                
            }
            getAllEventVenue(selectedEvent.value)
        }catch(error){
            toast.error("Something went wrong");
            console.log(error);
        }
    }
})

    useEffect(() => {

        fetchAll();
        return () => {

        }
    }, [])
    const toggle = () => {
        setModal(!modal);
        validation.resetForm();
    };

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEvents();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.slugId,
                    label: item.eventName
                }
            })
            setEventOptions(eventOptions);

        } catch (error) {
            console.log(error);
        }
    }


    const getAllEventVenue = async (slugId) => {
        try {
            const resp = await getEventVenuesBySlug(slugId);
            setEventVenues(resp)
        } catch (error) {
            console.log(error);
        }
    }
    const columns = useMemo(
        () => [

            {
                header: 'Venue Name',
                accessorKey: 'venueName',
                enableColumnFilter: false,
                enableSorting: true,

            },

            {
                header: 'Address',
                accessorKey: 'venueAddress',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Event Date',
                accessorKey: 'eventDate',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Created',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                  return (
                    <div>
                      {
                        cell.getValue() === 'active' ? (
                          <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>
                        ) : (
                          <span className="badge badge-soft-danger font-size-11 m-1">{cell.getValue()}</span>
                        )
                      }
        
                    </div>
                  );
                },
              },


            {
                header: 'Action',
                cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="card-drop">
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">

                                <DropdownItem
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        onClickDelete(data);
                                    }}>
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                                    Delete
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </DropdownItem>
                                <DropdownItem onClick={()=>{
                                    setIsEdit(true)
                                    setModal(true)
                                    validation.setValues({
                                        venueId:cellProps.row.original.venueId,
                                        venueName:cellProps.row.original.venueName,
                                        venueAddress:cellProps.row.original.venueAddress,
                                        eventDate:cellProps.row.original.eventDate
                                    })
                                    setSelectedStatus({value:cellProps.row.original.status,label:cellProps.row.original.status})
                                }}>
                                    <i className="mdi mdi-pencil font-size-16 text-primary me-1" id="deletetooltip"></i>
                                    Edit
                                </DropdownItem>

                            </DropdownMenu>

                        </UncontrolledDropdown>
                    );
                }
            },
        ],
        []
    );
    const handleClickNew = () => {
        toggle();
    }

    //meta title
    document.title = "Event Venue List | EventoX";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Venue List" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setIsLoading} />
                                :
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <Row className="mb-3">
                                                <Col xl="5">
                                                    <div className="col-md-12">
                                                        <Select
                                                            value={selectedEvent}
                                                            placeholder="Select Event"
                                                            onChange={(data) => {
                                                                setSelectedEvent(data)
                                                                getAllEventVenue(data.value)
                                                            }}
                                                            options={eventOptions}
                                                            width="100%"
                                                            className="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <TableContainer
                                                    columns={columns}
                                                    data={eventVenues || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    SearchPlaceholder="Search..."
                                                    isCustomPageSize={true}
                                                    isAddButton={true}
                                                    handleUserClick={handleClickNew}
                                                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                                    buttonName="New Ticket"
                                                    tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    pagination="pagination"
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">{!!isEdit?"Edit Venue":"Add New Venue"}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={e=>{
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-3">
                                    <Label className="form-label">Venue Name</Label>
                                    <Input type="text" name="venueName" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.venueName} invalid={validation.touched.venueName && validation.errors.venueName?true:false}/>
                                    {validation.touched.venueName && validation.errors.venueName?<FormFeedback type="invalid">{validation.errors.venueName}</FormFeedback>:null}

                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Venue Address</Label>
                                    <Input type="text" name="venueAddress" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.venueAddress} invalid={validation.touched.venueAddress && validation.errors.venueAddress?true:false}/>
                                    {validation.touched.venueAddress && validation.errors.venueAddress?<FormFeedback type="invalid">{validation.errors.venueAddress}</FormFeedback>:null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Event Date</Label>
                                    <Input type="date" name="eventDate" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.eventDate} invalid={validation.touched.eventDate && validation.errors.eventDate?true:false}/>
                                    {validation.touched.eventDate && validation.errors.eventDate?<FormFeedback type="invalid">{validation.errors.eventDate}</FormFeedback>:null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <Select
                                        value={selectedStatus}
                                        onChange={(data) => {
                                            setSelectedStatus(data)
                                            validation.setValues({...validation.values,status:data.value})
                                        }}
                                        options={statusOptions}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <button type="submit" className="btn btn-success save-user">Submit</button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default EventVenue
